.nrv {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0 60px;

  .nrv-header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nrv-header-left {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nrv-header-npm {
        font-size: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .nrv-header-right {
      height: 40px;
    }
  }

  .nrv-desc {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .nrv-table {
    width: 100%;
    overflow: overlay;

    .ant-table-wrapper {
      min-width: 1200px;
    }

    .url-icon {
      color: #555;
      font-size: 24px;
      line-height: 24px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .nrv-datasource {
    margin-bottom: 24px;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .nrv-about {
    margin-bottom: 24px;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .nrv-datasource, .nrv-about {
    .nrv-content-detail {
      line-height: 28px;
    }
  }

  .nrv-copyright {
    height: 100px;
    padding: 20px 0;
  }
}

@media screen and (max-width: 991px) {
  .nrv {
    padding: 0 32px;
  }
}

@media screen and (max-width: 767px) {
  .nrv {
    padding: 0 16px;
  }
}
